// extracted by mini-css-extract-plugin
export var animate = "collage-module--animate--N3dc8";
export var backgroundLayer = "collage-module--background-layer--GDY9M";
export var backgroundTransparent = "rgba(0,0,0,0)";
export var baseColor = "#000";
export var container = "collage-module--container--mMfsH";
export var defaultForegroundColor = "#d9d9d9";
export var highlightColor = "#b99128";
export var overlayBackgroundBottom = "linear-gradient(0, #262626, transparent)";
export var overlayBackgroundColor = "#262626";
export var overlayBackgroundTop = "linear-gradient(0, transparent, #262626)";
export var rootBackgroundColor = "#151515";
export var textColor = "#fff";